<style lang="sass" scoped>

// @page :left
//   margin-left: 1cm

// @page :right
//   margin-left: 1cm
.page
  position: absolute
  top: 0px
  left: 0px
  padding: 0px
  z-index: 1000
  background-color: #fff
  width: 100%
  min-height: 100vh

.table
  tr, th, td
    border-color: #000 !important
    border-size: 1px
    color: #000
    font-weight: normal
  th
    border-bottom: 0
    font-weight: bold

  border-bottom: solid 1px #000
</style>
<style>
#app {
  font-size: 12px;
  color: #000;
  font-weight: normal;
  line-height: 1;
}
@media print{@page {size: A4 landscape}}
/* @media print{@page {size: A4 portrait}} */
</style>
<template lang="pug">
div.page
  //- .async(:class='{done: done}')
  table.table(v-if='document.id' ref='table')
    thead
      tr
        th(v-if='selected_keys["__ID"]') ID
        th(v-if='selected_keys["__LIST"]') Sheet
        th(v-if='selected_keys["__CREATED_AT"]') Created At
        th(v-if='selected_keys["__UPDATED_AT"]') Updated At
        th(v-for='col in document.config.cols' v-if='selected_keys[col.key]') {{col.label}}
    tbody
      tr(v-for='row in rows')
        td(v-if='selected_keys["__ID"]') {{row.id}}
        td(v-if='selected_keys["__LIST"]') {{document.name}}
        td(v-if='selected_keys["__CREATED_AT"]') {{row.created_at}}
        td(v-if='selected_keys["__UPDATED_AT"]') {{row.updated_at}}
        td(v-for='col in document.config.cols' v-if='selected_keys[col.key]') {{row.json[col.key]}}


</template>

<script>

const xlsx = require('xlsx')

import moment from "moment"

export default {
  name: 'index',
  props: ['property_id', 'document_id', 'record_id', 'on_updated'],
  components: {

  },
  computed: {

  },
  watch: {
    '$store.state.documents'() {
      this.load()
    },
    'document_id'() {
      this.load()
    },
    'record_id'() {
      this.load()
    },
  },
  data() {
    return {
      done: false,
      rows: [],
      document: {},
      selected_keys: {},
    }
  },
  async mounted() {
    await this.$store.dispatch('property', this.property_id)
    await this.$store.dispatch('documents', this.property_id, this.document_id)
    // this.loaded = true
    // this.load()
    // if (this.$store.state.documents) {
    //   this.load()
    // }

    // https://stackoverflow.com/questions/7439210/remove-styles-from-text-when-copying-cutting-using-css-or-javascript
    document.addEventListener('copy', function(e) {
      const text_only = document.getSelection().toString();
      const clipdata = e.clipboardData || window.clipboardData;
      clipdata.setData('text/plain', text_only);
      clipdata.setData('text/html', text_only);
      e.preventDefault();
    });
  },
  methods: {
    async load() {
      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.error = '해당 목록을 찾지 못했습니다.'
        return
      }
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records`, {

        })
        if (r?.data?.message != 'ok') throw new Error('고객 데이터 가져오기 실패')
        this.rows = r.data.rows.map(e => {
          e.json = JSON.parse(e.json) || {}
          return e
        })

        if (!this.$route.query.o) throw new Error('조회조건이 없습니다.')
        const opt = JSON.parse(this.$route.query.o)
        for (const f of opt.f) {
          this.$set(this.selected_keys, f, true)
        }

        this.done = true

        if (opt.action == 'print') {
          setTimeout(() => {
            window.print()
          }, 300);
        }
        if (opt.action == 'download') {
          setTimeout(() => {
            this.prepare_excel()
          }, 300);
        }

      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    prepare_excel() {
      const wb = xlsx.utils.book_new()

      const ws = xlsx.utils.table_to_sheet(this.$refs.table)

      xlsx.utils.book_append_sheet(wb, ws, this.document.name)
      xlsx.writeFile(wb, `noitaler-export-${moment().format('YYYY.MM.DD') + '-' + Date.now()}.xlsx`);
      // const wb = xlsx.utils.book_new()



      // const headers = []
      // if(this.selected_keys["__ID"]) {
      //   headers.push('ID')
      // }
      // if(this.selected_keys["__LIST"]) {
      //   headers.push('Sheet')
      // }
      // if(this.selected_keys["__CREATED_AT"]) {
      //   headers.push('Created At')
      // }
      // if(this.selected_keys["__UPDATED_AT"]) {
      //   headers.push('Updated At')
      // }
      // for (const e of this.document.config.cols) {
      //   headers.push(e.label)
      // }

      // const rows = this.rows.map(e => {
      //   const o = {}
      //   if(this.selected_keys["__ID"]) {
      //     o.
      //   }
      //   if(this.selected_keys["__LIST"]) {
      //     headers.push('Sheet')
      //   }
      //   if(this.selected_keys["__CREATED_AT"]) {
      //     headers.push('Created At')
      //   }
      //   if(this.selected_keys["__UPDATED_AT"]) {
      //     headers.push('Updated At')
      //   }
      // })

      // const ws = xlsx.utils.json_to_sheet(this.rows.map(e => e.json), {header:this.dcols.map(e => e.key), skipHeader:false});
      // xlsx.utils.book_append_sheet(wb, ws, this.document.name)
      // xlsx.writeFile(wb, `noitaler-export-${Date.now()}.xlsx`);
    },

  },
}
</script>
